<template>
    <div>
        <h1 style="margin-top: 40px;">404 NOT FOUND </h1>
    </div>
</template>
<script>
export default {
    name: "fzf",
}
</script>
<style lang="scss">
    
</style>