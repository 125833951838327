<template>
  <transition name="slide-fade">
    <div class="forban">
      <!--      <div class="modal_block" v-if="isIdPay">-->
      <!--        <p>-->
      <!--          Текст-->
      <!--        </p>-->
      <!--      </div>-->
      <b-modal id="modal-accountONE">
        <template #modal-header="">
          <h5 v-if="isIdPayType == 'tovar'"> Поздравляю, Вы успешно оплатили свой заказ.</h5>
          <h5 v-if="isIdPayType == 'sub'"> Поздравляю, Вы оформили подписку.</h5>
          <h5 v-if="isIdPayType == 'post'"> Поздравляю, Вы успешно оплатили свой пост.</h5>
        </template>
        <template #default="">
          <p v-if="isIdPayType == 'tovar'">
            Письмо для товара: <br>
            Поздравляю, Вы успешно оплатили свой заказ. <br>
            Шаг 1. Зайдите на свою эл. почту, которую, Вы указали при заказе и оплате выбранного товара! <br>
            Шаг 2. Мы отправили Вам письмо с ссылкой на скачивание оплаченного товара. <br>
            С жалобами и по всем техническим вопросам обращайтесь на нашу официальную почту support@neearby.com <br>
            Удачи!
          </p>
          <p v-if="isIdPayType == 'sub'">
            Как получить обучение по подписки: <br>
            Шаг 1. Зайдите на свою эл. почту, которую Вы указывали при заказе и оплате данной подписки! <br>
            Шаг 2. На Вашу эл. почту придёт письмо от Neearby, в котором будет пароль для входа [Авторизации] в Ваш
            аккаунт! <br>
            Шаг 3. Для входа: Авторизируйтесь. Зайдите на страницу автора у которого покупали подписку. По центру
            увидите ленту с постами. Найдите посты по Вашей подписки, которые будут доступны для просмотра. <br>
            Помните в любой момент Вы сможете отписаться от Вашей подписки! В разделе Мои подписки! <br>
            По всем техническим вопросам обращайтесь на нашу официальную эл. почту support@neearby.com <br>
            Удачи!
          </p>
          <p v-if="isIdPayType == 'post'">
            Как получить обучение по посту: <br>
            Шаг 1. Зайдите на свою эл. почту, которую Вы указывали при заказе и оплате данного поста! <br>
            Шаг 2. На Вашу эл. почту придёт письмо от Neearby, в котором будет пароль для входа [Авторизации] в Ваш
            аккаунт! <br>
            Шаг 3. Для входа: Авторизируйтесь. Зайдите на страницу автора у которого покупали пост. По центру увидите
            ленту с постами. Найдите посты по Вашей подписки, которые будут доступны для просмотра. <br>
            По всем техническим вопросам обращайтесь на нашу официальную эл. почту support@neearby.com <br>
            Удачи!
          </p>
        </template>
        <template #modal-footer="{ cancel }">
          <b-button variant="secondary" @click="cancel()"> Закрыть</b-button>
        </template>
      </b-modal>
      <div class="unbanned" v-if="author.status != 'banned'">
        <div class="maaincont" v-if="show">
          <div class="oblojkaa">
            <b-button
              variant="info"
              v-if="status.login == user.login"
              class="updateoblojka"
            >
              <input
                type="file"
                @change="uploadoblojka($event)"
                accept="image/*"
                id="upload"
                hidden
              />
              <label for="upload"> Изменить обложку</label>
            </b-button>
            <b-avatar
              :key="keyofelement"
              rounded
              id="oblojkacom"
              :src="
                'https://api.neearby.com/api/users/' +
                author.login +
                '/oblojka'
              "
              alt="Error 404"
              class="oblojka"
            ></b-avatar>
          </div>
          <div class="container__containers">
            <!--    Основная сетка-->
            <div class="forcontt">
              <div class="leftColumn">
                <avatarlinks
                  :user="user"
                  :login="author.id"
                  :author="author"
                  :subc="author.subc"
                  :subcPay="author.subcPay"
                  :status="status"
                  :tovarPay="author.tovarPay"
                />
                <anonssub :user="user" />

                <div class="neearbyads">
                  <a href="https://t.me/neearbycom1_bot" target="_blank" style="text-decoration: none;">
                  <img src='../assets/banner1.png' style="width: 100%" v-if="randomValue === 0">
                  <img src='../assets/banner2.png' style="width: 100%" v-if="randomValue === 1">

                    <h3 style="padding-top: 15px;">ЖМИ СЮДА И ПОЛУЧИ
                      ВКУСНЫЙ ПОДАРОК!</h3>
                  </a>
                </div>


              </div>

              <div class="centerColumn">
                <abouauthor v-if="author.login" :author="author" :user="user" :name="author.login" />
                <b-card style="margin-top: 20px" v-if="">


                  <!--                  Начало-->


                  <div class="mobilecor" v-if="author.login == user.login && author.login == 'lereduard'">
                    <div class="carousel">
                      <carousel :responsive="{0:{items:1,nav:false},600:{items:3,nav:true}}" :nav="false"
                                v-if="author.tovar" class="carousel">
                        <div
                          class="item"
                          v-for="tovar in author.tovar"
                          :key="tovar.id"
                        >
                          <p class="pcoros">
                            {{ tovar.tittle }}
                          </p>
                          <b-icon
                            v-if="user.login != author.login"
                            :id="'compl' + tovar.id + tovar.tittle"
                            icon="three-dots-vertical"
                            style="
                          fill: black;
                          float: right;
                          position: absolute;
                          top: 45px;
                          right: 4px;
                        "
                          >
                          </b-icon>
                          <b-popover
                            :target="'compl' + tovar.id + tovar.tittle"
                            triggers="hover"
                            placement="bottom"
                            style="height: 100%"
                          >
                            <template #title>Действие</template>
                            <b-button
                              variant="outline"
                              @click="complaintovar(tovar.id)"
                            >Пожаловаться
                            </b-button
                            >
                            <b-button
                              variant="outline"
                              @click="copyLink(author.login,tovar.id,'tovar',user.login)"
                            >Скопировать <br> ссылку
                            </b-button
                            >
                          </b-popover>
                          <div @click="reload(tovar.id)" style="cursor: pointer">
                            <b-avatar
                              square
                              class="obks"
                              :src="
                            'https://api.neearby.com/api/publication/storage/' +
                            tovar.img
                          "
                              alt=""
                            >
                            </b-avatar>
                          </div>
                          {{ tovar.desription }} <br />
                          <div class="butto" style="float: bottom">
                            <b-link
                              style="font-size: 12px"
                              :href="'https://' + author.login + '.neearby.com/tovars'"
                              variant="info"
                              class="qwerty"
                            >Другие товары автора
                            </b-link>
                            <p
                              style="margin-top: 10px; margin-bottom: 0">
                              {{ tovar.cost }} ₽
                            </p>
                            <br />
                            <b-button-group>
                              <b-button
                                variant="info"
                                style="margin-top: 5px; font-size: 13px;border-radius: 10px;margin-right: 10px;background-color: gray;"
                                @click="deletet(tovar.id)"
                                v-if="status.login == user.login"
                              >Удалить
                              </b-button>
                              <b-button
                                variant="info"
                                style="margin-top: 5px; font-size: 13px;border-radius: 10px;background-color: gray;"
                                :href="
                              'https://' +
                              author.login +
                              '.neearby.com/changetovar/' +
                              tovar.id
                            "
                                v-if="status.login == user.login"
                              >Изменить
                              </b-button>
                            </b-button-group>
                            <b-button
                              variant="info"
                              style="height: 40px"
                              @click="buytov(tovar.id, tovar.tittle)"
                              v-if="status.login != user.login"
                            >Купить за {{ tovar.cost }} ₽
                            </b-button>
                            <br>
                            <b-button
                              v-if="tovar.referral"
                              variant="info"
                              style="max-width: 170px; margin-top: 10px"
                              @click="beReferral(tovar.id)"
                            >Скопировать партнерскую ссылку
                            </b-button>
                          </div>
                          <b-modal ref="modalkek" :id="tovar.id + tovar.tittle">
                            <template #modal-header="">
                              <!-- Emulate built in modal header close button action -->
                              <h5>Купить товар "{{ tovar.tittle }}"</h5>
                            </template>

                            <template #default="">
                              <label for="name">
                                <h5>
                                  Вы уверены, что хотите купить товар за
                                  {{ tovar.cost }} Р?
                                </h5>
                                <p>
                                  Товар придёт вам в личные сообщения через
                                  некоторое время после успешной покупки
                                </p>
                              </label>
                            </template>

                            <template #modal-footer="{ cancel }">
                              <p style="color: red">{{ erno }}</p>
                              <!-- Emulate built in modal footer ok and cancel button actions -->
                              <b-button
                                variant="info"
                                size="lg"
                                v-on:click="buytovv(tovar.id)"
                              >Купить
                              </b-button>
                              <b-button variant="info" size="lg" @click="cancel()">
                                Выйти
                              </b-button>
                              <!-- Button with custom close trigger value -->
                            </template>
                          </b-modal>
                        </div>
                      </carousel>
                    </div>
                    <b-button
                      variant="info"
                      style="
                      margin-top: 15px;
                      font-size: 18 px;
                      width: 300px;
                      height: 50px;
                      font-family: Inter, Arial, sans-serif;
                    "
                      @click="locat()"
                      v-if="status.login == user.login"
                    >
                      <b-icon icon="upload"></b-icon>
                      Добавить товар
                    </b-button>
                    <br />
                  </div>


                  <!--                  Конец-->


                  <div class="mobilecor" v-if="author.login !== 'lereduard'">
                    <div class="carousel">
                      <carousel :responsive="{0:{items:1,nav:false},600:{items:3,nav:true}}" :nav="false"
                                v-if="author.tovar" class="carousel">
                        <div
                          class="item"
                          v-for="tovar in author.tovar"
                          :key="tovar.id"
                        >
                          <p class="pcoros">
                            {{ tovar.tittle }}
                          </p>
                          <b-icon
                            v-if="user.login != author.login"
                            :id="'compl' + tovar.id + tovar.tittle"
                            icon="three-dots-vertical"
                            style="
                          fill: black;
                          float: right;
                          position: absolute;
                          top: 45px;
                          right: 4px;
                        "
                          >
                          </b-icon>
                          <b-popover
                            :target="'compl' + tovar.id + tovar.tittle"
                            triggers="hover"
                            placement="bottom"
                            style="height: 100%"
                          >
                            <template #title>Действие</template>
                            <b-button
                              variant="outline"
                              @click="complaintovar(tovar.id)"
                            >Пожаловаться
                            </b-button
                            >
                            <b-button
                              variant="outline"
                              @click="copyLink(author.login,tovar.id,'tovar',user.login)"
                            >Скопировать <br> ссылку
                            </b-button
                            >
                          </b-popover>
                          <div @click="reload(tovar.id)" style="cursor: pointer">
                            <b-avatar
                              square
                              class="obks"
                              :src="
                            'https://api.neearby.com/api/publication/storage/' +
                            tovar.img
                          "
                              alt=""
                            >
                            </b-avatar>
                          </div>
                          {{ tovar.desription }} <br />
                          <div class="butto" style="float: bottom">
                            <b-link
                              style="font-size: 12px"
                              :href="
                            'https://' + author.login + '.neearby.com/tovars'
                          "
                              variant="info"
                              class="qwerty"
                            >Другие товары автора
                            </b-link>
                            <p
                              style="margin-top: 10px; margin-bottom: 0">
                              {{ tovar.cost }} ₽
                            </p>
                            <br />
                            <b-button-group>
                              <b-button
                                variant="info"
                                style="margin-top: 5px; font-size: 13px;border-radius: 10px;margin-right: 10px;background-color: gray;"
                                @click="deletet(tovar.id)"
                                v-if="status.login == user.login"
                              >Удалить
                              </b-button>
                              <b-button
                                variant="info"
                                style="margin-top: 5px; font-size: 13px;border-radius: 10px;background-color: gray;"
                                :href="
                              'https://' +
                              author.login +
                              '.neearby.com/changetovar/' +
                              tovar.id
                            "
                                v-if="status.login == user.login"
                              >Изменить
                              </b-button>
                            </b-button-group>
                            <b-button
                              variant="info"
                              style="height: 40px"
                              @click="buytov(tovar.id, tovar.tittle)"
                              v-if="status.login != user.login"
                            >Купить за {{ tovar.cost }} ₽
                            </b-button>
                            <br>
                            <b-button
                              v-if="tovar.referral"
                              variant="info"
                              style="max-width: 170px; margin-top: 10px"
                              @click="beReferral(tovar.id)"
                            >Скопировать партнерскую ссылку
                            </b-button>
                          </div>
                          <b-modal ref="modalkek" :id="tovar.id + tovar.tittle">
                            <template #modal-header="">
                              <!-- Emulate built in modal header close button action -->
                              <h5>Купить товар "{{ tovar.tittle }}"</h5>
                            </template>

                            <template #default="">
                              <label for="name">
                                <h5>
                                  Вы уверены, что хотите купить товар за
                                  {{ tovar.cost }} Р?
                                </h5>
                                <p>
                                  Товар придёт вам в личные сообщения через
                                  некоторое время после успешной покупки
                                </p>
                              </label>
                            </template>

                            <template #modal-footer="{ cancel }">
                              <p style="color: red">{{ erno }}</p>
                              <!-- Emulate built in modal footer ok and cancel button actions -->
                              <b-button
                                variant="info"
                                size="lg"
                                v-on:click="buytovv(tovar.id)"
                              >Купить
                              </b-button>
                              <b-button variant="info" size="lg" @click="cancel()">
                                Выйти
                              </b-button>
                              <!-- Button with custom close trigger value -->
                            </template>
                          </b-modal>
                        </div>
                      </carousel>
                    </div>
                    <b-button
                      variant="info"
                      style="
                      margin-top: 15px;
                      font-size: 18 px;
                      width: 300px;
                      height: 50px;
                      font-family: Inter, Arial, sans-serif;
                    "
                      @click="locat()"
                      v-if="status.login == user.login"
                    >
                      <b-icon icon="upload"></b-icon>
                      Добавить товар
                    </b-button>
                    <br />
                  </div>
                </b-card>
                <br />
                <div class="aboutpost">
                  <b-card>
                    <h4>Посты</h4>
                    <b-form-select
                      placeholder="По классу"
                      class="selectionn"
                      v-model="selectedd"
                      :options="options"
                    ></b-form-select>
                    <b-form-select
                      placeholder="По классу"
                      class="selectionn"
                      v-model="selectedt"
                      :options="optionst"
                    ></b-form-select>
                  </b-card>
                </div>

                <div
                  class="postuser"
                  v-for="post in posts"
                  :key="post.id"
                  v-if="selectedd == 'Все посты' && selectedt == 'Сначала новые'"
                >
                  <postuser
                    :user="user"
                    :status="status"
                    :subs="author.subs"
                    :post="post"
                    :author="author"
                  />
                </div>
                <div
                  class="postuser"
                  v-for="post in posts.slice().reverse()"
                  :key="post.id"
                  v-if="selectedd == 'Все посты' && selectedt == 'Сначала старые'"
                >
                  <postuser
                    :user="user"
                    :status="status"
                    :subs="author.subs"
                    :post="post"
                    :author="author"
                  />
                </div>
                <div
                  class="postuser"
                  v-for="post in sorted"
                  :key="post.id"
                  v-if="
                  selectedd == 'Доступные мне' && selectedt == 'Сначала новые'
                "
                >
                  <postuser
                    :user="user"
                    :status="status"
                    :subs="author.subs"
                    :post="post"
                    :author="author"
                  />
                </div>
                <div
                  class="postuser"
                  v-for="post in sorted.slice().reverse()"
                  :key="post.id"
                  v-if="
                  selectedd == 'Доступные мне' && selectedt == 'Сначала старые'
                "
                >
                  <postuser
                    :user="user"
                    :status="status"
                    :subs="author.subs"
                    :post="post"
                    :author="author"
                  />
                </div>

                <div class="block_button_load_more" v-if="paginationValue > offSet">
                  <a class="button_load_more" @click="loadPost">Загрузить ещё</a>
                </div>
                <!-- <div
            class="postuser"
            v-for="post in posts.slice().reverse()"
            :key="post.id"
            v-if="selected='Все посты' && selectedt == 'Сначала новые'"
          >
            <postuser
              :user="user"
              :status="status"
              :subs="author.subs"
              :post="post"
            />
          </div>
                  <div
            class="postuser"
            v-for="post in posts.slice().reverse()"
            :key="post.id"
            v-if="selected='Все посты' && selectedt == 'Сначала новые'"
          >
            <postuser
              :user="user"
              :status="status"
              :subs="author.subs"
              :post="post"
            />
          </div> -->
              </div>

              <div class="rigthColumn">
                <subscription
                  v-if="author.login"
                  :user="user"
                  :author="author"
                  :subs="author.subs"
                  :status="status"
                  :subc="author.subc"
                />
                <addbutton subid="" v-if="status.login == user.login" style="" />
              </div>
            </div>
          </div>

        </div>
      </div>
      <div class="banned" v-if="author.status == 'banned'">
        <h1>АККАУНТ БЫЛ ЗАБЛОКИРОВАН</h1>
        <h5>Причина: {{ author.Name }}</h5>
        <h5>Свяжитесь, пожалуйста с почтой support@neearby.com</h5>
      </div>
      <input type="text" style="opacity: 0;" id="myInput1">
    </div>

  </transition>
</template>
<script>
// import carousel from "./carousel.vue";
import subscription from "./subscription.vue";
import abouauthor from "./abouauthor.vue";
import avatarlinks from "./avatarlinks.vue";
import buybutton from "./buybutton.vue";
import addbutton from "./addbutton.vue";
import postuser from "./postuser.vue";
import anonssub from "./anonssub.vue";
import carousel from "vue-owl-carousel";
import Api from "../api";
import { getCookie, setCookie, deleteCookie } from "../utils.js";
import axios from "axios";

export default {
  name: "account",
  data() {
    return {
      isIdPayType: "",
      isIdPay: false,
      forCopy: "",
      paginationValue: 0,
      pagination: 0,
      offSet: 0,
      show: false,
      keyofelement: 0,
      sorted: [],
      posts: [],
      erno: "",
      selectedd: "Все посты",
      selectedt: "Сначала новые",
      isNoLoad: true,
      options: [
        { value: "Все посты", text: "Все посты" },
        { value: "Доступные мне", text: "Доступные мне" }
      ],
      optionst: [
        { value: "Сначала новые", text: "Сначала новые" },
        { value: "Сначала старые", text: "Сначала старые" }
      ],
      randomValue: Math.floor(Math.random() * 2)
    };
  },
  components: {
    anonssub,
    carousel,
    subscription,
    abouauthor,
    avatarlinks,
    addbutton,
    buybutton,
    postuser
  },
  props: {
    status: Object,
    user: Object,
    author: Object
  },
  methods: {
    showModalONE() {
      setTimeout(() => {
        this.$bvModal.show("modal-accountONE");
      }, 1000);
    },
    copyLink(login, id, type, loginUser) {
      if (loginUser != login) {
        let _genLink;
        if (type == "sub") {
          _genLink = "https://" + login + ".neearby.com/forsub/" + id;
        }
        if (type == "tovar") {
          _genLink = "https://" + login + ".neearby.com/forproduct/" + id;
        }
        // this.forCopy = _genLink
        // if (this.user.id) {
        //   _genLink += "?refer=" + this.user.id;
        // }
        const copyText = document.getElementById("myInput1");
        copyText.value = _genLink;
        /* Select the text field */
        setTimeout(() => {
          console.log("Первый if");
          copyText.select();
          /* Copy the text inside the text field */
          document.execCommand("copy");
          alert("Текст успешно скопирован");
        }, 100);
      } else {
        let _genLink;
        if (type == "sub") {
          _genLink = "https://" + login + ".neearby.com/forsub/" + id;
        }
        if (type == "tovar") {
          _genLink = "https://" + login + ".neearby.com/forproduct/" + id;
        }
        // this.forCopy = _genLink
        // if (this.user.id) {
        //   _genLink += "?refer=" + this.user.id
        // }
        const copyText = document.getElementById("myInput1");
        copyText.value = _genLink;
        /* Select the text field */
        setTimeout(() => {
          console.log("Первый if");
          copyText.select();
          /* Copy the text inside the text field */
          document.execCommand("copy");
          alert("Текст успешно скопирован");
        }, 100);
      }
    },
    async loadPost() {
      this.offSet += 1;
      const json = {};
      let cht = getCookie("token");
      if (cht == undefined || cht == "undefined" || !cht || cht === undefined) {
        cht = "user";
      }
      console.log(cht);
      if (cht || cht !== undefined || cht !== "undefined") {
        json.token = cht;
      } else {
        json.token = "user";
      }
      const hosts = window.location.host.split(".");
      const result = await Api.users.vrotebal(hosts[0], json, this.offSet);
      console.log(result);
      this.paginationValue = result.pagination;
      result.posts.forEach((item) => {
        this.posts.push(item);
      });
      const arr = [];
      this.posts.forEach((post) => {
        if (post.attachments) {
          arr.push(post);
        }
      });
      this.sorted = arr;
    },
    reload(id) {
      window.location.href = "/forproduct/" + id;
    },
    complaintovar(id) {
      this.$bvModal.show("modal-complain");
      let str = JSON.stringify({
        id: id,
        type: "tovar"
      });
      localStorage.setItem("complain", str);
    },
    beReferral: async function(productId){

      const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      };
      let result = await axios.post(`https://api.neearby.com/api/referral/create`, {
        id_goods: productId,
        type: "tovar"
      }, config);

      if(result.statusCode){
        if(result.statusCode == 400){
          await navigator.clipboard.writeText(result.refLink);
        }
        this.$toast.success(result.message, '', {
          position: "topCenter"
        });
        return;
      }

      await navigator.clipboard.writeText(result);
      this.$toast.success(result, 'Ваша реферальная ссылка уже скопирована', {
        position: "topCenter"
      });
    },
    async buytov(id, tittle) {
      if (this.user.error) {
        this.$bvModal.show("modal-scoped");
        let str = JSON.stringify({
          id: id,
          type: "tovar"
        });
        localStorage.setItem("buy", str);
      } else {
        this.$bvModal.show(id + tittle);
      }
    },
    async buytovv(id) {
      let result = await Api.users.buycurtovar(
        id,
        localStorage.getItem("token")
      );
      if (!result.message) {
        window.location.href = result;
      } else {
        this.erno = result.message;
      }

      console.log(result);
    },
    async deletet(tovar) {
      let result = await Api.users.deletetovar(
        tovar,
        localStorage.getItem("token")
      );
      if (result == true) {
        window.location.reload();
      } else {
        console.log(result.message);
      }
    },
    locat() {
      window.location.href = "/newproduct";
    },
    async uploadoblojka(event) {
      const data = new FormData();
      data.append("photo", event.target.files[0]);
      console.log(data);
      const result = await Api.users.updateoblojka(
        data,
        localStorage.getItem("token")
      );
      console.log(result);
      if (result === true) {
        document.getElementById("oblojkacom").src =
          "https://api.neearby.com/api/users/" +
          this.author.login +
          "/oblojka?a=" +
          Math.floor(Math.random() * 10123);
        window.location.reload();
      }
    }
  },
  async mounted() {
    const idPay = this.$route.query.pay;
    const idPay_Type = this.$route.query.type;
    console.log("блаблабла111111");
    console.log(idPay);
    if (idPay && idPay_Type) {
      this.showModalONE();
      console.log("Вошли2");
      this.isIdPay = true;
      this.isIdPayType = idPay_Type;
    }
    this.author;
    console.log(this.author);
    setTimeout(() => {
      this.show = true;
    }, 100);
    const json = {};
    let cht = getCookie("token");
    if (cht == undefined || cht == "undefined" || !cht || cht === undefined) {
      cht = "user";
    }
    console.log(cht);
    if (cht || cht !== undefined || cht !== "undefined") {
      json.token = cht;
    } else {
      json.token = "user";
    }
    const hosts = window.location.host.split(".");
    const result = await Api.users.vrotebal(hosts[0], json, this.offSet);
    console.log(result);
    this.posts = result.posts;
    this.paginationValue = result.pagination;
    const arr = [];
    this.posts.forEach((post) => {
      if (post.attachments) {
        arr.push(post);
      }
    });
    this.sorted = arr;
  },
  computed: {
    postAtt: function() {
      return this.posts;
    }
  }
};
</script>
<style lang="scss">
.modal_block {
  display: flex;
  position: absolute;
  width: 3000px;
  height: 3000px;
  background-color: #ffffff;
  z-index: 100000;
}

.forban {
  background-color: #e9e9e9;
}

.button_load_more {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 300px;
  height: 50px;
  background-color: #fe6637;
  text-decoration: none;
  border: 1px solid #fe6637;
  border-radius: 6px;
  font-weight: 650;
  font-size: 16px;
  color: #ffffff;
}

.block_button_load_more {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 25px;
  margin-bottom: 15px;
}

.owl-nav {
  display: none;
}

.banned {
  display: none;
  padding: 3%;
  display: grid;
}

.leftColumn {
  position: relative;
  top: -220px;
  z-index: 10;
}

.qwerty {
  margin-bottom: 15px;
  text-decoration: none;
  color: darkgrey;
}

.item {
  text-align: center;
}

.oblojkaa {
  width: 100%;
  height: 270px;
  background-color: #bebebe;
  overflow: hidden;
}

.container__containers {
  margin: 0 auto;
  width: 90%;
  height: 100%;
}

.forcontt {
  text-align: center;
  display: grid;
  // grid-template-columns: 0.8fr 3.6fr 280px;
  grid-template-columns: 20% 57% 20%;
  padding-top: 50px;
  grid-column-gap: 20px;
}

.btn-primary {
  font-size: 16px;
}

.btn-info {
  font-size: 16px;
}

.btn-secondary {
  font-size: 16px;
}

.selectionn {
  outline: none;
}

.pcoros {
  font-weight: 700;
  font-size: 14px;
  min-height: 40px;
}

.owl-stage {
  display: flex;
}

@media only screen and (min-width: 2200px) {
  .container__containers {
    width: 1940px;
  }
}

@media only screen and (max-width: 1470px) {
  .forcontt {
    grid-template-columns: 20% 57% 20%;
  }
}

@media only screen and (max-width: 1150px) {
  .pcoros {
    min-height: 83px;
  }
  .container__containers {
    width: 90%;
  }
  .leftColumn > div > button {
    font-size: 14px;
  }
}

@media only screen and (max-width: 1150px) {
  .forcontt {
    grid-template-columns: 20% 57% 19%;
  }
  .pcoros {
    min-height: 83px;
  }
  .container__containers {
    width: 90%;
  }
  .leftColumn > div > button {
    font-size: 14px;
  }
}

@media only screen and (max-width: 600px) {
  .forcontt {
    display: flex;
    flex-direction: column;
  }
  .carousel {
    width: 90vw;
  }
  .leftColumn {
    position: relative;
    top: -20px;
    z-index: 10;
    order: 0;
  }
  .centerColumn {
    order: 2;
  }
  .rigthColumn {
    order: 1;
  }
  .oblojkaa {
    height: 140px;
  }
  .pcoros {
    min-height: 83px;
  }
  .mobilecor {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

}


.subbuttonnn {
  width: 200px;
  height: 40px;
  margin-top: 30px;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .oblojka {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    z-index: 1;
  }
}

.updateoblojka {
  position: absolute;
  right: 80px;
  margin-top: 220px;
  z-index: 3;
}

.postuser {
  margin-top: 20px;
}

.aboutpost {
  text-align: left;
  margin-top: 10px;
}

.centerColumn {
  width: 100%;
}

.maaincont {
  background-color: #e9e9e9;
}

.selectionn {
  display: inline-block;
  width: 50%;
  height: 40px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

//
//.forcont {
//  display: grid;
//  grid-template-columns: 300px 720px 300px;
//}
//
//.subscription {
//  width: 270px;
//  right: 90px;
//}
//
.carousel {
  width: 49vw;
}

.tittle {
  text-align: center;
  max-width: 20ch;
}

.obks {
  width: 100%;
  height: 100%;
  margin-left: 50%;
  transform: translate(-50%, 0);
}

.owl-carousel .owl-item img {
  width: 100%;
  height: 100%;
}

//
.slide-fade-enter-active {
  transition: all 1.3s ease;
}

.slide-fade-leave-active {
  transition: all 2s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active до версии 2.1.8 */
{
  transform: translateX(10px);
  opacity: 0;
}

//.subbutton {
//  margin-top: 10px;
//  width: 270px;
//}
//
//.leftcont {
//  position: relative;
//  width: 270px;
//  top: -150px;
//}
//
//.rightcont {
//  margin-left: 100px;
//}

.neearbyads{
  margin-top: 20px;
  width: 100%;
}

</style>
