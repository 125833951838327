<template>
  <About v-if="isProfile" />
  <Home v-else />
</template>

<script>
import About from "@/views/About.vue";
import Home from "@/views/Home.vue";

export default {
  name: "isProfile.vue",
  components: {Home, About},
  data() {
    return {
      isProfile: false
    };
  },
  mounted() {
    const _hosts = window.location.host.split(".");
    console.log(_hosts);
    if (_hosts.length == 3) {
      console.log(123);
      this.isProfile = true;
    }
  }
};
</script>

<style scoped>

</style>